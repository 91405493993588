import React, { useState, useEffect, useContext } from 'react';

import PrinterTable from './printerTable';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row'; 

import { DataContext } from '../../init/getData';
import toast from 'react-hot-toast';

function PrinterHeader(props) {

  const { api, userData, settingData, refreshSetting } = useContext(DataContext);

  const [showAddModal, setShowAddModal] = useState(null);

  const [isActive, setIsActive] = useState('true');
  const [search, setSearch] = useState('');

  const [salesPrinter, setSalesPrinter] = useState({ value: null, label: '' });
  const [name, setName] = useState(settingData.printingName);
  const [address, setAddress] = useState(settingData.printingAddress);
  const [phone, setPhone] = useState(settingData.printingPhone);
  const [invoiceWidth, setInvoiceWidth] = useState(settingData.invoiceWidth);


  const addModal = () => {
    setShowAddModal(!showAddModal);
  }

  const handleSubmit = async () => {

    let data = {
      printingName: name,
      printingAddress: address,
      printingPhone: phone,
      invoiceWidth: invoiceWidth,
    }

    let res = await api.post(`/printer/editSettings`, JSON.stringify(data))
    if (res.data.status) {
      toast.success(res.data.message);
      refreshSetting();
    }
    else {
      toast.error(res.data.message);
    }

  }


  return (
    <div className='card'>


   


      <PrinterTable
        showAddModal={showAddModal}
        addModal={addModal}
        isActive={isActive}
        search={search} />

      <div style={{ userSelect: 'none', height: 'auto', display: 'flex', flexDirection: 'row', marginLeft: '10px' }}>

        <Form style={{ width: '45%', margin: '0', marginTop: '30px' }} id='settingForm'>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3}>Name</Form.Label>
            <Col sm={9}>
              <div>
                <Form.Control defaultValue={settingData.printingName} value={name} onChange={(e) => setName(e.target.value)} type="text" style={{ width: '70%' }} />
              </div>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3}>Address</Form.Label>
            <Col sm={9}>
              <div>
                <Form.Control value={address} onChange={(e) => setAddress(e.target.value)} type="text" style={{ width: '70%' }} />
              </div>
            </Col>
          </Form.Group>


          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3}>Phone</Form.Label>
            <Col sm={9}>
              <div>
                <Form.Control value={phone} onChange={(e) => setPhone(e.target.value)} type="text" style={{ width: '70%' }} />
              </div>
            </Col>
          </Form.Group>


          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3}>Width</Form.Label>
            <Col sm={9}>
              <div>
                <Form.Control value={invoiceWidth} onChange={(e) => setInvoiceWidth(e.target.value)} type="text" style={{ width: '70%' }} />
              </div>
            </Col>
          </Form.Group>

        </Form>

        <Form style={{ width: '45%', margin: '0', marginTop: '30px' }} id='settingForm'>


        </Form>

      </div>

      <div className="modal-footer" style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ textAlign: 'center' }}>
          <input type="button" className="btn btn-primary" value="Save Data" onClick={handleSubmit}></input>
        </div>
      </div>

    </div>
  )
}

export default PrinterHeader;
