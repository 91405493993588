import toast, {Toaster} from 'react-hot-toast';



const saveOrder = async(api, tableId, cart, rate, closePaymentDone, toggleModal, total) => {
  console.log(cart);
  

  let data = {
    tableId: tableId,
    cart: cart,
    rate: rate,
  }

  let res = await api.post(`dineIn/saveCart`, JSON.stringify(data));
  if(res.data.status){

    // Group cart items by printerId
    const printerGroups = cart.reduce((acc, item) => {
      if (!acc[item.printerId]) {
        acc[item.printerId] = [];
      }
      acc[item.printerId].push(item);
      return acc;
    }, {});

    // Loop over each printer group and call printIp
    for (const printerId in printerGroups) {
      const printerCart = printerGroups[printerId];
      let printData = {
        tableId: tableId,
        cart: printerCart,  // Only the products for this printer
        total: total,
        rate: rate,   
      };

      await printKitchen(api, printData, printerId);  
    }

    toast.success(res.data.message);
    closePaymentDone();
    toggleModal();
  } else {
    toast.error(res.data.message);
  }
}


const printKitchen = async(api, printData, printerId) => {

  
  let print = await api.post(`print/printKitchen`, JSON.stringify(printData));
  if(print.data.status){
  }else{
    toast.error(print.data.message);
  }

}

const printIp = async(api, printData, printerId) => {

  
  let print = await api.post(`print/printIp`, JSON.stringify(printData));
  if(print.data.status){
  }else{
    toast.error(print.data.message);
  }

}



const editOrder = async(api, orderId, cart, total, discount, isTva, tva, rate, closePaymentDone) => {

  if(cart.length == 0){
    toast.error('cart is empty')
    return
  }
    
  let data = {
    orderId: orderId,
    cart: cart,
    total: total,
    discount: discount,
    tva: isTva? tva : 0,
    rate: rate,
  }
  let res = await api.post(`order/editCart`, JSON.stringify(data));
  if(res.data.status){
    toast.success(res.data.message);
    closePaymentDone();
  }else{
    toast.error(res.data.message);
  }

}

export {
  saveOrder,
};