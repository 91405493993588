import React, { useState, useEffect, useContext } from 'react';


import SimpleTable from '../../components/tables/simpleTable';

import TrashModal from '../../components/modals/trashModal';
import RecoverModal from '../../components/modals/recoverModal';
import AddModal from '../../components/modals/addModal';
import EditModal from '../../components/modals/editModal';
import DeleteModal from '../../components/modals/deleteModal';

import SearchFilter from '../../components/filters/search';
import CustomButton from '../../components/buttons/customButton';
import IsActiveFilter from '../../components/filters/isActive';
import AddButton from '../../components/buttons/addButton';
import DataFilter from '../../components/filters/dataFilter';
import DatePicker from '../../components/filters/datePicker';

import { FaPlus } from "react-icons/fa";

import Loader from '../../components/loader/loader';
import { numberFormatter, totalFormatter } from '../../components/formatters/numberFormatter';
import { dateFormatter } from '../../dynamic/dateFormatter';
import { clientOrderColumnDefs } from '../../components/tables/columnDefs';
import { dateTableFormatter } from '../../dynamic/dateFormatter';
import { typeFormatter, stockActivitytypeFormatter } from '../../components/formatters/defFormatter';
import { quantityInFormatter, quantityOutFormatter } from '../../components/formatters/defFormatter';

import toast from 'react-hot-toast';

import { Space, Input, Tag, Button, Tabs } from 'antd';


import Select from 'react-select';
import { Col, Form, Row, DropdownButton, Dropdown } from 'react-bootstrap';


import { FiEdit } from "react-icons/fi";
import { FcUndo, FcViewDetails, FcFlowChart, FcRules } from "react-icons/fc";
import { BiSolidTrash, BiBarcodeReader } from "react-icons/bi";
import { IoBarcodeOutline } from "react-icons/io5";
import { FcPlus } from "react-icons/fc";
import { HiMinusCircle } from "react-icons/hi";
import AsyncDropDownFilter from '../../components/filters/asyncDropdownFilter';



import { DataContext } from '../../init/getData';




function StockActivity(props) {

  let { } = props;


  const { userData, settingData, api, wareHouseData, refWareHouseData, refProductData, productData, devData } = useContext(DataContext);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  const createdBy = parseInt(userData.id);
  const userRole = userData.role;
  const defaultWareHouseId = userData?.role?.defaultWareHouseId;

  const today = new Date().toISOString().split('T')[0];

  const [idRow, setIdRow] = useState([]);
  const [refreshTable, setRefreshTable] = useState(true);
  const [columnDefs, setColumnDefs] = useState([]);
  const [filter, setFilter] = useState({ search: '', isActive: 'true', fromDate: today, toDate: today, isOrder: false, type: 'Production', wareHouseId: defaultWareHouseId, transactionType: null, productId: null });

  const [showAddModal, setShowAddModal] = useState(false);
  const [showTrashModal, setShowTrashModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRecoverModal, setShowRecoverModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [loadingCounter, setLoadingCounter] = useState(0);

  const [name, setName] = useState('');
  const [category, setCategory] = useState([]);

  const table = 'stockActivity';
  const title = 'Stock Activity';


  //Get Data 
  useEffect(() => {
    const fetchData = async () => {
      try {
        await refWareHouseData();
        await refProductData();
        setIsLoading(false);
      } catch (error) {
        console.error('Error refreshing data Contact Support');
      }
    };
    fetchData();
  }, []);


  // WareHouse Options
  const wareHouseOptions = wareHouseData?.map(item => ({
    value: item.id,
    label: item.name,
  }))
  wareHouseOptions.push({label: 'All '+ devData.wareHouseName, value: null} );
  const selectedWareHouse = wareHouseOptions.filter(option => option.value === filter.wareHouseId);

  const inStockProducts = productData.filter(product => product.type == 'production');

    // Product Options
    const productOptions = inStockProducts?.map(item => ({
      value: item.id,
      label: `${item.sku ? ` (${item.sku}) ` : ''}  ${item.name} ${item.barcode ? ` [${item.barcode}]` : ''}`,
      unit: item.unit,
    }))

    productOptions.push({
      value: null,
      label: 'All Products',
    })

    const selectedProduct = productOptions.find(option => option.value === filter.productId);



  const addModal = () => {
    setIdRow({});
    resetForm();
    setShowAddModal(!showAddModal);
  }

  const resetForm = () => {
    setCategory({ createdBy: createdBy })
  }

  const refreshTableFunction = () => {
    setRefreshTable(!refreshTable)
  }

  const recoverModal = (e) => {
    if (!showRecoverModal) {
      setIdRow(e);
    }
    setShowRecoverModal(!showRecoverModal);
  }

  const trashModal = (e) => {
    if (!showTrashModal) {
      setIdRow(e);
    }
    setShowTrashModal(!showTrashModal);
  }

  const deleteModal = (e) => {
    if (!showDeleteModal) {
      setIdRow(e);
    }
    setShowDeleteModal(!showDeleteModal);
  }

  const editModal = (e) => {
    if (!showEditModal) {
      setIdRow(e);
    }
    setShowEditModal(!showEditModal);
  }

  // idRow Change
  useEffect(() => {
    setCategory(
      {
        name: idRow?.name || '',
        createdBy: createdBy,
      }
    )
  }, [idRow]);




  //Excel Data
  const getExcelData = (data, getSummary) => {

    const transformedData = [];
    data.forEach(item => {

      const newItem = {
        id: item.id,
        createdAt: item.createdAt,
        barcode: item.product?.barcode,
        name: item.product?.name,
        quantity: item.quantity,
        cost: item.cost,
        averageCost: item.averageCost,
        transaction: item.transaction?.type,
        details: item.transaction?.details,
        user: item.transaction?.created?.name,
        cumQty: item.cumulativeQuantity,
      };

      transformedData.push(newItem);
    });

    const summary = {
      TotalIn :getSummary.totalIn,
      TotalOut :getSummary.totalOut,
      Quantity :getSummary.totalIn - getSummary.totalOut,
    }

    return {transformedData, summary};
  };

  let newColumnDefs = [
    { title: 'ID', dataIndex: 'id', width: '4%', sorter: (a, b) => a.age - b.age,},
    { title: 'CreatedAt', dataIndex: 'createdAt', render: dateTableFormatter, width: '10%'  },
    { title: 'Date', dataIndex: 'transaction', render: (transaction) => (transaction?.date), width: '8%'},
    { title: 'Barcode', dataIndex: 'product', render: (product) => (product?.barcode), width: '10%'},
    { title: 'Product', dataIndex: 'product', render: (product) => (product?.name), width: '10%'},    
    { title: 'Total In', dataIndex: 'quantity', width: '8%', render: quantityInFormatter },
    { title: 'Total Out', dataIndex: 'quantity', width: '8%', render: quantityOutFormatter },
    { title: 'Transaction', dataIndex: 'transaction', render: stockActivitytypeFormatter , width: '10%' },
    { title: 'Details', dataIndex: 'transaction', render: (transaction) => (transaction.details)},
    { title: 'T-ID', dataIndex: 'transaction', render: (transaction) => (transaction.id), width: '4%'},
    { title: 'User', dataIndex: 'transaction', render: (transaction) => (transaction?.created?.name)},
    { title: 'Cum', dataIndex: 'cumulativeQuantity'},
  ];
  

  useEffect(() => {  
  
    setColumnDefs(newColumnDefs);

  }, []);


  const formView = (
    <div style={{ userSelect: 'none' }}>
    </div >);




  const modalFooter = (
    <div>
    </div>
  );

  const tableFooter = (

    <div style={{ display: 'flex', justifyContent: 'space-between', position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
      <div>
      <span className='table-footer footer-green'>Total In: {numberFormatter(data?.summary?.totalIn, 2)}{selectedProduct?.unit}</span>
      <span className='table-footer footer-red'>Total Out: {numberFormatter(data?.summary?.totalOut, 2)}{selectedProduct?.unit}</span>
        <span className='table-footer footer-one'>Quantity: {numberFormatter(data?.summary?.totalIn - data?.summary?.totalOut, 2)}{selectedProduct?.unit}</span>
      </div>
    </div>

  );


  return (
    <div className='card'>

      {isLoading &&
        <Loader />
      }


      {!isLoading &&
        <>
          <div>

            <SearchFilter setFilter={setFilter} />
            <DatePicker setFilter={setFilter} filter={filter} />
            <DataFilter selectedOption={selectedWareHouse} setFilter={setFilter} dataOptions={wareHouseOptions} filterBy={'wareHouseId'} placeHolder={'All WareHouse'} />
            <AsyncDropDownFilter 
                setFilter={setFilter} 
                filterBy={'productId'} 
                placeHolder={'All Product'} 
                filter={filter} 
                setLoadingCounter={() => setLoadingCounter((prevCounter) => prevCounter + 1)} 
            />

          </div>
  


          <SimpleTable
            filter={filter}
            showAddModal={showAddModal}
            addModal={addModal}
            table={table}
            title={title}
            columnDefs={columnDefs}
            idRow={idRow}
            setIdRow={setIdRow}
            refreshTable={refreshTable}
            data={data}
            setData={setData}
            tableFooter={tableFooter}
            api={api}
            getExcelData={getExcelData}
            selection={true}
          />

          <TrashModal
            showModal={showTrashModal}
            toggleModal={trashModal}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            api={api}
          />

          <DeleteModal
            showModal={showDeleteModal}
            toggleModal={deleteModal}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            api={api}
          />

          <RecoverModal
            showModal={showRecoverModal}
            toggleModal={recoverModal}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            api={api}
          />

          <AddModal
            showModal={showAddModal}
            toggleModal={addModal}
            formView={formView}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            size={'md'}
            resetForm={resetForm}
            footer={modalFooter}
            api={api}
            data={{ category }}
          />

          <EditModal
            showModal={showEditModal}
            toggleModal={editModal}
            formView={formView}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            size={'md'}
            footer={modalFooter}
            api={api}
            data={{ id: idRow?.id, category }}
          />


        </>
      }

    </div>
  )
}

export default StockActivity;
