import moment from 'moment';
const productionprint = async (printData) => {
  const { cart, compounds, invoiceId } = printData; // Corrected 'ivoiceId' to 'invoiceId'

  const form = document.createElement("form");

  // Generate the cart items HTML with enhanced design
  const cartItemsHTML = cart.map((item, index) => `
    <div style="padding: 20px; font-family: 'Segoe UI', Arial, sans-serif; border: 1px solid #ddd; margin-bottom: 20px; border-radius: 8px;">
      <div style="text-align: center; margin-bottom: 10px;">
        <h4 style="font-weight: bold; font-size: 20px; color: #2c3e50; margin: 0;">
          Invoice ID: ${invoiceId} 
        </h4>
      </div>
      <div style="text-align: left; margin-bottom: 10px;">
        <h4 style="font-weight: bold; font-size: 20px; color: #2c3e50; margin: 0;">
          ${index + 1}. ${item.name} x ${item.quantity}
        </h4>
        <p style="font-size: 14px; color: #7f8c8d; margin: 5px 0;">Category: ${item.category}</p>
      </div>
      ${compounds.filter(compound => compound.cartId === item.id).length > 0 ? `
        <div style="margin-top: 10px;">
          <h5 style="font-weight: bold; font-size: 16px; color: #34495e; margin-bottom: 5px;">Included Compounds:</h5>
          <ul style="list-style-type: none; padding: 0; margin: 0;">
            ${compounds.filter(compound => compound.cartId === item.id).map((compound, compIndex) => `
              <li key="${compIndex}" style="font-size: 14px; font-weight: 500; color: #2d3436; line-height: 1.6; background: #f0f0f0; padding: 5px; border-radius: 4px; margin-bottom: 4px;">
                ${compound.compoundName} x ${compound.value}
              </li>
            `).join('')}
          </ul>
        </div>
      ` : ''}
    </div>
  `).join('');

  form.innerHTML = `
    <style>
      @media print {
        @page { margin: 0; }
        body { margin: 0; padding: 20px; }
      }
      .invoice-container {
        width: 100%;
        max-width: 850px;
        margin: auto;
        font-family: 'Segoe UI', Arial, sans-serif;
        color: #2c3e50;
      }
      .invoice-header {
        text-align: center;
        padding: 20px 0;
        border-bottom: 2px solid #2c3e50;
        margin-bottom: 20px;
      }
      .invoice-header h2 {
        margin: 0;
        font-size: 26px;
        font-weight: bold;
        color: #1abc9c;
      }
      .invoice-footer {
        text-align: center;
        padding-top: 20px;
        border-top: 2px solid #2c3e50;
        margin-top: 30px;
        font-size: 14px;
        color: #7f8c8d;
      }
      .cart-items {
        margin-bottom: 30px;
      }
      .cart-item {
        border: 1px solid #ddd;
        padding: 15px;
        border-radius: 8px;
        margin-bottom: 15px;
        background-color: #f9f9f9;
      }
      h4, h5 {
        margin: 0;
      }
    </style>
    
    <div class="invoice-container">

      <div class="invoice-header">
        <h2>Production Summary</h2>
      </div>

      <div class="cart-items">
        ${cartItemsHTML}
      </div>

      <div class="invoice-footer">
        <p>Generated on: ${moment(printData.date).format('MM-DD-YYYY')}</p>
      </div>
    </div>
  `;

  // Open a new window to display the form content
  const newWindow = window.open("", "_blank", "width=850,height=700");
  newWindow.document.body.appendChild(form);

  // Trigger the print action
  newWindow.print();

  // Close the window after printing
  newWindow.close();
};

export {
  productionprint as productionprint,
};
