// const link = 'http://localhost:5011/api';
//const link = 'http://localhost:5099/api';

//const link = 'https://admin.bakeryalhadi.com:5011/api';

//const link = 'https://production.bakeryalhadi.com:5014/api';

//const link = 'https://accounting.eastmedsolutions.com:5007/api';
//const link = 'https://fashionsista.riserteam.com:5010/api';
//const link = 'https://thebarberlounge.riserteam.com:5012/api';

//const link = 'http://localhost:5000/api';

// const link = 'https://bonprix.riserteam.com:5016/api';

// const link = 'https://demo.riserteam.com:5015/api';

// const link = 'https://projects.riserteam.com:5019/api';

const link = 'https://wtraders.riserteam.com:5021/api';

export  { link};