import React, { useState, useEffect, useContext } from 'react';
import SimpleTable from '../../components/tables/simpleTable';
import TrashModal from '../../components/modals/trashModal';
import RecoverModal from '../../components/modals/recoverModal';
import AddModal from '../../components/modals/addModal';
import EditModal from '../../components/modals/editModal';
import SearchFilter from '../../components/filters/search';
import IsActiveFilter from '../../components/filters/isActive';
import AddButton from '../../components/buttons/addButton';
import Loader from '../../components/loader/loader';
import { Col, Form, Row, DropdownButton, Dropdown } from 'react-bootstrap';
import { FiEdit } from "react-icons/fi";
import { FcUndo, FcViewDetails, FcFlowChart, FcRules } from "react-icons/fc";
import { BiSolidTrash, BiBarcodeReader } from "react-icons/bi";
import { DataContext } from '../../init/getData';

function PrinterTable(props) {


  const { userData, settingData, serverApi, api } = useContext(DataContext);
  const userRole = userData.role;
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const createdBy = parseInt(userData.id);


  const [idRow, setIdRow] = useState([]);
  const [refreshTable, setRefreshTable] = useState(true);
  const [columnDefs, setColumnDefs] = useState([]);
  const [filter, setFilter] = useState({ search: '', isActive: 'true' });

  const [showAddModal, setShowAddModal] = useState(false);
  const [showTrashModal, setShowTrashModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRecoverModal, setShowRecoverModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);



  const [printer, setPrinter] = useState([]);

  const table = 'printer';
  const title = 'printer';


  const addModal = () => {
    setIdRow({});
    resetForm();
    setShowAddModal(!showAddModal);  
  }

  const resetForm = () => {
    setPrinter({
      createdBy: createdBy,

    })
  }

  const refreshTableFunction = () => {
    setRefreshTable(!refreshTable)
  }

  const recoverModal = (e) => {
    if (!showRecoverModal) {
      setIdRow(e);
    }
    setShowRecoverModal(!showRecoverModal);
  }

  const trashModal = (e) => {
    if (!showTrashModal) {
      setIdRow(e);
    }
    setShowTrashModal(!showTrashModal);
  }


  const editModal = (e) => {
    if (!showEditModal) {
      setIdRow(e);
    }
    setShowEditModal(!showEditModal);
  }

  // idRow Change
  useEffect(() => {
    setPrinter(
      {
        name: idRow?.name || '',
        interface: idRow?.interface || '',
        type: idRow?.type || '',
        ipAddress: idRow?.ipAddress || '',
        tittle: idRow?.tittle || '',
        phone: idRow?.phone || '',
      }
    )
  }, [idRow]);


  // Generic handler for input changes
  const handleInputChange = (e) => {
    let { name, value } = e.target;
    if (value === 'true' || value === 'false') {
      value = JSON.parse(value)
    }
    setPrinter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };


  //Excel Data
  const getExcelData = (data) => {

    const transformedData = [];
    data.forEach(item => {

      const newItem = {
        id: item.id,
        name: item.name,
        phone: item.phone,
        interface: item.interface,
      };

      transformedData.push(newItem);
    });

    return transformedData;
  };


  let newColumnDefs = [
    { title: 'ID', dataIndex: 'id', width: '5%' },
    { title: 'Name', dataIndex: 'name' },
    { title: 'Interface', dataIndex: 'interface' },
    { title: 'Type', dataIndex: 'type' },
    { title: 'IP Address', dataIndex: 'ipAddress' },
    { title: 'Title', dataIndex: 'tittle' }, 
    { title: 'Phone', dataIndex: 'phone' },
  ];

  useEffect(() => {

    if (filter.isActive == 'true') {
      newColumnDefs.push(
        {
          title: 'Edt',
          width: '5%',
          render: (_, record) => (
            <FiEdit size={16} style={{ cursor: 'pointer', color: '#2E70A5' }} onClick={() => editModal(record)} />
          ),
        },
        {
          title: 'Del',
          width: '5%',
          render: (_, record) => (
            <BiSolidTrash size={18} style={{ cursor: 'pointer', color: '#e6434f' }} onClick={() => trashModal(record)} />
          ),
        }
      );
    } else {
      newColumnDefs.push({
        title: 'Rec',
        width: '5%',
        render: (_, record) => (
          <FcUndo size={20} style={{ cursor: 'pointer' }} onClick={() => recoverModal(record)} />
        ),
      });
    }
    setColumnDefs(newColumnDefs);

  }, [filter.isActive]);

  const formView = (
    <div style={{ userSelect: 'none' }}>

        <Row className="mb-3" style={{ marginTop: '2%' }}>

    <Form.Group as={Col}>
    <Form.Label>Name</Form.Label>
    <input type="text"  
    name="name" 
    id="name" 
    className="form-control"
    value={printer.name}
    onChange={handleInputChange}
    ></input>
     </Form.Group>

  <Form.Group as={Col}>
      <Form.Label>interface</Form.Label>

      <select className="form-control" id='interface' name='interface' value={printer.interface || 'USB'}  onChange={handleInputChange} >
      <option value='USB'>USB</option>
      <option value='LAN'>LAN</option>
      </select>

    </Form.Group>

         </Row>

         <Row className="mb-3" style={{ marginTop: '2%' }}>

            <Form.Group as={Col}>
            <Form.Label>Type</Form.Label>

            <select className="form-control" id='type' name='type' value={printer.type || 'invoice'}  onChange={handleInputChange} >
            <option value='invoice'>invoice</option>
            <option value='section'>section</option>
            </select>

            </Form.Group>

            <Form.Group as={Col}>
                <Form.Label>IpAddress</Form.Label>
                <input type="text"  name="ipAddress" id="ipAddress" value={printer.ipAddress}
                    onChange={handleInputChange} className="form-control"></input>
            </Form.Group>

            </Row>

            <Row className="mb-3" style={{ marginTop: '2%' }}>

            <Form.Group as={Col}>
          <Form.Label>Tittle</Form.Label>
          <input type="text"  name="tittle" id="tittle" value={printer.tittle}
            onChange={handleInputChange} className="form-control"></input>
        </Form.Group>

      <Form.Group as={Col}>
          <Form.Label>Phone</Form.Label>
          <input type="text"  name="phone" id="phone" value={printer.phone}
            onChange={handleInputChange} className="form-control"></input>
        </Form.Group>

            </Row>
    </div>
  );
  

  const modalFooter = (
    <div>
    </div>
  );

  const tableFooter = (
    <></>
  );


  return (
    <div className='card'>

      {isLoading &&
        <Loader />
      }


      {!isLoading &&
        <>
          <div>

            <SearchFilter setFilter={setFilter} />
            <IsActiveFilter setFilter={setFilter} />
            <AddButton addModal={addModal} />

          </div>



          <SimpleTable
            filter={filter}
            showAddModal={showAddModal}
            addModal={addModal}
            table={table}
            title={title}
            columnDefs={columnDefs}
            idRow={idRow}
            setIdRow={setIdRow}
            refreshTable={refreshTable}
            data={data}
            setData={setData}
            tableFooter={tableFooter}
            api={api}
            getExcelData={getExcelData}
          />

          <TrashModal
            showModal={showTrashModal}
            toggleModal={trashModal}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            api={api}
            data={{ id: idRow.id }}
          />

          <RecoverModal
            showModal={showRecoverModal}
            toggleModal={recoverModal}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            api={api}
          />

          <AddModal
            showModal={showAddModal}
            toggleModal={addModal}
            formView={formView}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            size={'lg'}
            resetForm={resetForm}
            footer={modalFooter}
            api={api}
            data={{  printer }}
          />

          <EditModal
            showModal={showEditModal}
            toggleModal={editModal}
            formView={formView}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            size={'lg'}
            footer={modalFooter}
            api={api}
            data={{ id: idRow?.id,  printer }}
          />


        </>
      }

    </div>
  )
}

export default PrinterTable;
