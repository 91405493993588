import React, { useState, useEffect } from 'react';

const PinCode = ({ onSubmit }) => {
    const [pinCode, setPinCode] = useState(''); // Holds the entered pin code
  
    // Handle clicking on the keypad
    const handleKeyPress = (value) => {
      if (value === 'backspace') {
        setPinCode((prev) => prev.slice(0, -1)); // Remove the last character
      } else if (value === 'submit') {
        // onSubmit(pinCode); // No need to submit here, we'll handle submission in the form
      } else {
        setPinCode((prev) => prev + value); // Add the clicked value to the pin
      }
    };
  
    // Call onSubmit when the full pinCode is ready for form submission
    useEffect(() => {
      onSubmit(pinCode);
    }, [pinCode, onSubmit]);
  
    return (
      <div style={{ textAlign: 'center', margin: 'auto', maxWidth: '250px' }}>
        {/* Input field to display typed PIN code */}
        <input
          type="password"
          value={pinCode}
          readOnly
          style={{
            width: '100%',
            height: '40px',
            fontSize: '24px',
            textAlign: 'center',
            marginBottom: '15px',
            border: '2px solid #ccc',
            borderRadius: '5px',
          }}
        />
  
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '15px', textAlign: 'center' }}>
          {/* Render buttons for the numbers 1-9 */}
          {['1', '2', '3', '4', '5', '6', '7', '8', '9'].map((char) => (
            <button
              key={char}
              type="button" // Prevent form submission
              style={{
                width: '60px',
                height: '60px',
                fontSize: '24px',
                backgroundColor: '#f0f0f0',
                borderRadius: '50%',
                border: '2px solid #ccc',
                cursor: 'pointer',
                boxShadow: '2px 2px 5px rgba(0,0,0,0.2)',
              }}
              onClick={() => handleKeyPress(char)}
            >
              {char}
            </button>
          ))}
  
          {/* Submit button (@) */}
                <button
                type="button" // Prevent form submission
                style={{
                width: '60px',
                height: '60px',
                fontSize: '24px',
                backgroundColor: '#f0f0f0',
                borderRadius: '50%',
                border: '2px solid #ccc',
                cursor: 'pointer',
                boxShadow: '2px 2px 5px rgba(0,0,0,0.2)',
                }}
                onClick={() => handleKeyPress('@')}
                >
                @
                </button>

  
          {/* Zero Button */}
          <button
            type="button" // Prevent form submission
            style={{
              width: '60px',
              height: '60px',
              fontSize: '24px',
              backgroundColor: '#f0f0f0',
              borderRadius: '50%',
              border: '2px solid #ccc',
              cursor: 'pointer',
              boxShadow: '2px 2px 5px rgba(0,0,0,0.2)',
            }}
            onClick={() => handleKeyPress('0')}
          >
            0
          </button>
  
          {/* Backspace Button (⌫) */}
          <button
            type="button" // Prevent form submission
            style={{
              width: '60px',
              height: '60px',
              fontSize: '24px',
              backgroundColor: '#f0f0f0',
              borderRadius: '50%',
              border: '2px solid #ccc',
              cursor: 'pointer',
              boxShadow: '2px 2px 5px rgba(0,0,0,0.2)',
            }}
            onClick={() => handleKeyPress('backspace')}
          >
            &#9003;
          </button>
        </div>
      </div>
    );
  };
  
  export default PinCode;
  
