import React, { useState, useEffect, useContext } from 'react';
import toast from 'react-hot-toast';

import { DataContext } from '../../init/getData';
import SalesModal from './salesModal';
import Loader from '../../components/loader/loader'; // Import the custom Loader

function DineIn() {

  const { api, tablesData } = useContext(DataContext);
  
  const [showSalesModal, setShowSalesModal] = useState(false);
  const [selectedTable, setSelectedTable] = useState(null);
  const [loading, setLoading] = useState(false); // Loading state for table data
  const [carts, setCarts] = useState({}); // Track carts for each table

  // Fetch cart data for all tables when the component mounts
  useEffect(() => {
    const fetchCartsData = async () => {
      setLoading(true); // Start loading
      try {
        const cartPromises = tablesData.map(async (table) => {
          const res = await api.get(`/dineIn/getCart`, { params: { tableId: table.id } });
          return { tableId: table.id, cartData: res.data.status ? res.data.data : [] };
        });
        const cartsData = await Promise.all(cartPromises);
        const cartsMap = cartsData.reduce((acc, { tableId, cartData }) => {
          acc[tableId] = cartData; // Populate carts state
          return acc;
        }, {});
        setCarts(cartsMap);
      } catch (error) {
        toast.error('Error fetching cart data');
      } finally {
        setLoading(false); // Stop loading when done
      }
    };

    fetchCartsData();
  }, [tablesData, api]);

  const selectTable = (table) => {
    if (table) {
      setSelectedTable(table);
      setShowSalesModal(true);
    } else {
      setShowSalesModal(false);
      setSelectedTable(null);
    }
  };

  return (
    <div className='card'>
      {loading && <Loader />} {/* Show loader when data is being fetched */}

      {!loading && tablesData && (    
        <div className='card-container' style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(120px, 1fr))', gap: '10px' }}>
          {Array.from({ length: Math.max(...tablesData.map(table => table.position)) }).map((_, index) => {
            const table = tablesData.find(table => table.position === String(index + 1));
        
            return (
              <div
                className={table ? 'card' : ''}
                onClick={table ? () => selectTable(table) : null}
                key={index}
                style={{
                  border: table ? '1px solid #000' : 'none',
                  backgroundColor: table && carts[table.id] && carts[table.id].length > 0 ? 'lightgreen' : 'transparent', // Change color based on cart status
                  margin: '0px',
                  justifyContent: 'center',
                  textAlign: 'center',
                  width: '100%',  
                  height: '100px',
                  cursor: 'pointer',
                  gridColumn: `${(index % 11) + 1} / span 1`,
                  gridRow: `${Math.floor(index / 11) + 1} / span 1`,
                }}
              >
                {table && (
                  <div style={{ fontSize: '20px', fontWeight: 'bold', textAlign: 'center' }}>{table.name}</div>
                )}
              </div>
            );
          })}
        </div>
      )}

      {showSalesModal && (
        <SalesModal 
          showModal={showSalesModal}
          toggleModal={() => setShowSalesModal(!showSalesModal)}
          tableId={selectedTable}
        />
      )}
    </div>
  );
}

export default DineIn;
