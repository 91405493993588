import React, { useState, useEffect, useContext } from 'react';
import toast from 'react-hot-toast';
import { Modal } from 'react-bootstrap';
import { DataContext } from '../../init/getData';
import TableSales from './tableSales/tableSales';
import Loader from '../../components/loader/loader'; 

const SalesModal = ({ showModal, toggleModal, tableId }) => {
  const { api } = useContext(DataContext);
  const [cart, setCart] = useState(null);
  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    const fetchCartData = async () => {
      try {
        if (tableId) {
          const res = await api.get(`/dineIn/getCart`, { params: { tableId: tableId.id } });
          if (res.data.status) {
            setCart(res.data.data);
          } else {
            toast.error('Failed to fetch cart data');
          }
        }
      } catch (error) {
        toast.error('Error fetching cart data');
      } finally {
        setLoading(false); // Stop loading after fetching data
      }
    };

    fetchCartData();
  }, [tableId, api]);

  return (
    <Modal show={showModal} size="xl" backdrop="static" onHide={toggleModal}>
      <div style={{ width: '130%', marginLeft: '-15%' }}>
      {loading && <Loader />} 
        <Modal.Body style={{ height: '10vh', marginTop: '-20px' }}>
          {loading ? (
            <Loader /> 
          ) : (
            cart && (
              <TableSales
                toggleModal={toggleModal}
                cart={cart}
                setCart={setCart}
                tableId={tableId}
              />
            )
          )}
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default SalesModal;
