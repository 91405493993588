import React, { useState, useEffect, useContext, useRef } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { numberFormatter } from '../../dynamic/numberFormatter';
import { roundedNumberFormatter } from '../formatters/numberFormatter';
import ComponentToPrint from '../print/componentToPrint';

import { DataContext } from '../../init/getData';
import { FcPortraitMode, FcAbout, FcInTransit, FcHome } from "react-icons/fc";
import ReactToPrint from 'react-to-print';
import { datePrintFormatter } from '../../dynamic/dateFormatter';
import { Button } from 'antd';
import { productionprint } from './productionPrintModal';

const ProductionModal = (props) => {

  const { userData, settingData, serverApi, localApi, devData } = useContext(DataContext);
  const userId = userData.id;
  const userName = userData.name; 
  const userRole = userData.role.name;
  const [compounds, setCompounds] = useState([]);
  const [loading, setLoading] = useState(false);
  const fetchedTransactionId = useRef(null);
  const componentRef = useRef();
  const [printData, setPrintData] = useState(null);


  let api;
  if (!devData?.isServer && !devData?.isCloud) {
    api = serverApi;
  } else {
    api = localApi;
  }

  const { showModal, toggleModal, idRow, transaction } = props;


  const cart = idRow?.cart;
  const printCart = () => {


    let printData = {
      cart: cart,
      compounds: compounds,
      invoiceId: idRow.id,
      date: idRow.date, 
    }
    productionprint(printData)
  }

  useEffect(() => {
    const fetchCompounds = async () => {
        if (showModal && fetchedTransactionId.current !== transaction.id) {
            setLoading(true);
            try {
                const response = await api.post('/transaction/getCartCompound', {
                    transactionId: transaction.id,
                });
                setCompounds(response.data.data); 
                fetchedTransactionId.current = transaction.id; // Store the transaction id after successful fetch
            } catch (error) {
                console.error('Error fetching compounds:', error);
                toast.error('Failed to load compounds.');
            } finally {
                setLoading(false);
            }
        }
    };

    fetchCompounds();
}, [showModal, transaction.id]); 


  useEffect(() => {
    if (idRow) {
      setPrintData({
        cart: cart,
        total: idRow.total,
        settingData: settingData,
        discount: idRow.discount,
        userName: userName,
        invoiceId: idRow.id,
        customer: idRow.account,
        delivery: idRow.delivery,
        date: idRow.date,    
      })
    }
  }, [idRow]);



  return (
    <Modal show={showModal} backdrop="static" size='xl' onHide={toggleModal}>
      <Modal.Header style={{ background: "#39aaa5", display: "flex", justifyContent: "center" }}>
        <div>
          <Modal.Title style={{ color: "white" }}> Transaction ID :  {idRow.id}</Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>  


       <div>
                      {Array.isArray(cart) && cart.length > 0 ? (
                        cart.map((item, index) => (
                          <div key={index} style={{ padding: '15px', borderBottom: '1px solid #ddd' }}>
                            {/* Main item title */}
                            <Row style={{ textAlign: 'center', marginBottom: '20px' }}>
                              <Col>
                                <h5 style={{ fontWeight: 'bold' }}>
                                  {item.name} x {item.quantity}
                                </h5>
                              </Col>
                            </Row>
                            {/* Display compounds */}
                            {compounds.length > 0 && (
                              <Row style={{ textAlign: 'center' }}>
                                <Col style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                                  {compounds.filter(compound => compound.cartId === item.id).map((compound, compIndex) => (
                                    <span
                                      key={compIndex}
                                      style={{
                                        fontSize: '17px',
                                        fontWeight: '500',
                                        color: '#555',
                                        lineHeight: '2',
                                        textAlign: 'center'
                                      }}
                                    >
                                      {compound.compoundName} x {compound.value}
                                    </span>
                                  ))}
                                </Col>
                              </Row>
                            )}
                          </div>
                        ))
                      ) : (
                        <p>No items in the cart</p>
                      )}
       </div>


        <div className="modal-footer" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
         
          <div className="modal-footer" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      
          </div>
          
          <div style={{ display: 'flex', gap: '10px' }}>
            <button type="button" className="btn btn-secondary" style={{ backgroundColor: '#1f2937' }} onClick={toggleModal}>Close</button>
          
              <button type="button" style={{ backgroundColor: '#1d7790' }} className="btn btn-primary" onClick={printCart}>Print</button>
          
           
          </div>
        </div>
        

       



      </Modal.Body>

    </Modal>
  );
};

export default ProductionModal;
