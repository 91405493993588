import React, { useState, useEffect, useContext } from 'react';


import SimpleTable from '../../components/tables/simpleTable';

import TrashModal from '../../components/modals/trashModal';
import RecoverModal from '../../components/modals/recoverModal';
import AddModal from '../../components/modals/addModal';
import EditModal from '../../components/modals/editModal';
import DeleteModal from '../../components/modals/deleteModal';

import SearchFilter from '../../components/filters/search';
import CustomButton from '../../components/buttons/customButton';
import IsActiveFilter from '../../components/filters/isActive';
import AddButton from '../../components/buttons/addButton';
import DataFilter from '../../components/filters/dataFilter';
import DatePicker from '../../components/filters/datePicker';
import CartModal from '../../components/modals/cartModal';
import DropDownFilter from '../../components/filters/dropDownFilter';

import { FaPlus } from "react-icons/fa";

import Loader from '../../components/loader/loader';
import { numberFormatter, totalFormatter } from '../../components/formatters/numberFormatter';
import { dateFormatter } from '../../dynamic/dateFormatter';
import { clientOrderColumnDefs } from '../../components/tables/columnDefs';
import { dateTableFormatter } from '../../dynamic/dateFormatter';
import { typeFormatter, paymentTypeFormatter } from '../../components/formatters/defFormatter';
import { creditFormatter, debitFormatter } from '../../components/formatters/defFormatter';

import toast from 'react-hot-toast';

import { Space, Input, Tag, Button, Tabs } from 'antd';

import { FcKindle } from "react-icons/fc";
import Select from 'react-select';
import { Col, Form, Row, DropdownButton, Dropdown } from 'react-bootstrap';


import { FiEdit } from "react-icons/fi";
import { FcUndo, FcViewDetails, FcFlowChart, FcRules } from "react-icons/fc";
import { BiSolidTrash, BiBarcodeReader } from "react-icons/bi";
import { IoBarcodeOutline } from "react-icons/io5";
import { FcPlus } from "react-icons/fc";
import { HiMinusCircle } from "react-icons/hi";



import { DataContext } from '../../init/getData';




function AllTransactions(props) {

  let { } = props;


  const { userData, settingData, serverApi, api, devData } = useContext(DataContext);
  const userRole = userData.role;
  const [loadingCounter, setLoadingCounter] = useState(0);
  const [data, setData] = useState([]);
  const createdBy = parseInt(userData.id);
  const defaultWareHouseId = userData?.role?.defaultWareHouseId;


  const today = new Date().toISOString().split('T')[0];

  const [idRow, setIdRow] = useState([]);
  const [refreshTable, setRefreshTable] = useState(true);
  const [columnDefs, setColumnDefs] = useState([]);
  const [filter, setFilter] = useState({ search: '', isActive: 'true', fromDate: today, toDate: today, isOrder: false, wareHouseId: defaultWareHouseId });

  const [showAddModal, setShowAddModal] = useState(false);
  const [showTrashModal, setShowTrashModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRecoverModal, setShowRecoverModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showCartModal, setShowCartModal] = useState(false);

  const [name, setName] = useState('');
  const [category, setCategory] = useState([]);

  const table = 'transaction';
  const title = 'All Transactions';


  const addModal = () => {
    setIdRow({});
    resetForm();
    setShowAddModal(!showAddModal);
  }

  const resetForm = () => {
    setCategory({ createdBy: createdBy })
  }

  const refreshTableFunction = () => {
    setRefreshTable(!refreshTable)
  }

  const recoverModal = (e) => {
    if (!showRecoverModal) {
      setIdRow(e);
    }
    setShowRecoverModal(!showRecoverModal);
  }

  const trashModal = (e) => {
    if (!showTrashModal) {
      setIdRow(e);
    }
    setShowTrashModal(!showTrashModal);
  }

  const deleteModal = (e) => {
    if (!showDeleteModal) {
      setIdRow(e);
    }
    setShowDeleteModal(!showDeleteModal);
  }

  const editModal = (e) => {
    if (!showEditModal) {
      setIdRow(e);
    }
    setShowEditModal(!showEditModal);
  }

  // idRow Change
  useEffect(() => {
    setCategory(
      {
        name: idRow?.name || '',
        createdBy: createdBy,
      }
    )
  }, [idRow]);


  // Generic handler for input changes
  const handleInputChange = (e) => {
    let { name, value } = e.target;
    if (value === 'true' || value === 'false') {
      value = JSON.parse(value)
    }
    setCategory((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  //Excel Data
  const getExcelData = (data, getSummary) => {

    const transformedData = [];
    data.forEach(item => {

      const newItem = {
        id: item.id,
        createdAt: item.createdAt,
        account: item.account?.name,
        delivery: item.delivery?.name,
        total: item.total,
        discount: item.discount,
        type: item.type,
        isCredit: item.isCredit,
        details: item.details,
        cumBalance: item.cumulativeBalance,
      };

      transformedData.push(newItem);
    });

    const summary = {
      Balance: getSummary.totalSalesCash + getSummary.totalSalesCash,
    }
    return { transformedData, summary };
  };

  const cartModal = (e) => {
    if (!showCartModal) {
      setIdRow(e);
    }
    setShowCartModal(!showCartModal);
  }


  let newColumnDefs = [
    { title: 'ID', dataIndex: 'id', width: '6%', sorter: (a, b) => a.age - b.age },
    { title: 'CreatedAt', dataIndex: 'createdAt', render: dateTableFormatter },
    { title: 'Date', dataIndex: 'date' },
    { title: 'Account', dataIndex: 'account', render: (account) => (account?.name) },
    { title: devData.deliveryName, dataIndex: 'delivery', render: (delivery) => (delivery?.name) },
    { title: 'Total In', dataIndex: 'total', width: '8%', render: debitFormatter },
    { title: 'Total Out', dataIndex: 'total', width: '8%', render: creditFormatter },
    { title: 'Discount %', dataIndex: 'discount', width: '8%', render: numberFormatter },
    { title: 'Type', dataIndex: 'type', render: typeFormatter },
    { title: 'Details', dataIndex: 'details' },
    { title: 'cum Balance', dataIndex: 'cumulativeBalance', render: numberFormatter },  
  ];


  useEffect(() => {

    newColumnDefs.push({
      title: 'View',
      width: '5%',
      render: (_, record) => (
        <FcKindle size={18} style={{ cursor: 'pointer', color: '#2E70A5' }} onClick={() => cartModal(record)} />
      ),
    });

    setColumnDefs(newColumnDefs);

  }, []);

  const formView = (
    <div style={{ userSelect: 'none' }}>


      <Row className="mb-3">
        {/* Name  */}
        <Form.Group as={Col}>
          <Form.Label className='formLabel'>Name<span style={{ color: 'red' }}> *</span></Form.Label>
          <Form.Control type="text" name='name'
            value={category.name}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
      </Row>

    </div >);




  const modalFooter = (
    <div>
    </div>
  );

  const tableFooter = (

    <div style={{ display: 'flex', justifyContent: 'space-between', position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
      <div>
        <span className='table-footer footer-green'>Total In: {numberFormatter(data?.summary?.totalIn, 2)} {settingData.currency}</span>
        <span className='table-footer footer-red'>Total Out: {numberFormatter(data?.summary?.totalOut, 2)} {settingData.currency}</span>
        <span className='table-footer footer-one'>Balance: {numberFormatter(data?.summary?.totalIn - data?.summary?.totalOut, 2)} {settingData.currency}</span>
      </div>
    </div>

  );


  return (
    <div className='card'>


      <div>

        <SearchFilter setFilter={setFilter} />
        <DatePicker setFilter={setFilter} filter={filter} />
        <DropDownFilter setFilter={setFilter} filterBy={'createdBy'} placeHolder={'All User'} filter={filter} setLoadingCounter={() => setLoadingCounter((prevCounter) => prevCounter + 1)} />
        <DropDownFilter setFilter={setFilter} filterBy={'wareHouseId'} placeHolder={'All ' + devData.wareHouseName} filter={filter} setLoadingCounter={() => setLoadingCounter((prevCounter) => prevCounter + 1)} />

        <select name="groupId" className="form-control" id="groupId"
          style={{ width: '14%' }}
          onChange={(e) => setFilter(prevFilter => ({
            ...prevFilter,
            type: e.target.value === 'null' ? null : e.target.value
          }))
          }
        >
          <option value='null' >All</option>

          <option value='Return'>Return</option>
          <option value='Sale'>Sale</option>
          <option value='Payment' >Payment</option>
          <option value='Receive'>Receive</option>

          <option value='ExpenseInvoice' >ExpenseInvoice</option>
          <option value='Production'>Production</option>

          <option value='StockControl' >StockControl</option>
          <option value='Transfer'>Transfer</option>

          <option value='PurchaseInvoice' >PurchaseInvoice</option>
          <option value='PurchaseReturn'>PurchaseReturn</option>
          <option value='SalesInvoice' >SalesInvoice</option>
          <option value='SalesReturn'>SalesReturn</option>

          <option value='EndOfDay'>EndOfDay</option>
        </select>


      </div>


      {loadingCounter < 2 &&
        <Loader />
      }


      {loadingCounter == 2 &&
        <>




          <SimpleTable
            filter={filter}
            showAddModal={showAddModal}
            addModal={addModal}
            table={table}
            title={title}
            columnDefs={columnDefs}
            idRow={idRow}
            setIdRow={setIdRow}
            refreshTable={refreshTable}
            data={data}
            setData={setData}
            tableFooter={tableFooter}
            api={api}
            getExcelData={getExcelData}
          />

          <TrashModal
            showModal={showTrashModal}
            toggleModal={trashModal}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            api={api}
          />

          <DeleteModal
            showModal={showDeleteModal}
            toggleModal={deleteModal}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            api={api}
          />

          <RecoverModal
            showModal={showRecoverModal}
            toggleModal={recoverModal}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            api={api}
          />

          <AddModal
            showModal={showAddModal}
            toggleModal={addModal}
            formView={formView}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            size={'md'}
            resetForm={resetForm}
            footer={modalFooter}
            api={api}
            data={{ category }}
          />

          <EditModal
            showModal={showEditModal}
            toggleModal={editModal}
            formView={formView}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            size={'md'}
            footer={modalFooter}
            api={api}
            data={{ id: idRow?.id, category }}
          />


          <CartModal
            showModal={showCartModal}
            toggleModal={cartModal}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            api={api}
          />

        </>
      }

    </div>
  )
}

export default AllTransactions;
